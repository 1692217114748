<template>
  <div class="download" v-show="this.download" >
      <div class="box">
        <div class="boxs">是否前往打开（下载）考研通APP？</div>
        <div class="bottom">
          <div class="left" @click='close'>取消</div>
  <div class="right" @click="open">打开(下载)</div>
        </div>
        <div v-show='this.aa==true'>点击右上角"···"选择用浏览器打开</div>
      </div>
  </div>
</template>

<script>
import request from "../../plugin/request";
export default {
  props:{
  },
  data() {
    return {  
    download:true,
    aa:false
    }
  },
  mounted(){
    this.$nextTick(() => {

    });
  },
  methods:{
    close() {
      window.history.back()    },
    open() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
         this.a()
      }else{
       try{
       if(navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)){
       window.location.href="kaoyantong://kytapp";
       }else if(navigator.userAgent.match(/android/i)){ 
          window.location.href="zixin://main.app"
       }
       }
     catch(e){
      }
      setTimeout(function(){
        if(navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          window.location.href="https://itunes.apple.com/cn/app/id1526934426?mt=8"
         }else if(navigator.userAgent.match(/android/i)) {
          window.location.href="https://kytapp.oss-cn-shanghai.aliyuncs.com/app-release.apk"
         }
        },2000);
      }
   },
   a() {
     this.aa=true
   }

  }
};
</script>
<style lang="less" scoped>
.download {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.2);
  z-index:1000;
  .box {
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 6.4rem;
    background-color: #ebeff2;
    font-size: 0.4rem;
    border-radius: 0.27rem;
    text-align: center;
   
    .boxs {
      padding:0.4rem 0.2rem;
      line-height: 1.5;
      border-bottom:0.01rem solid #caced1;
      box-sizing: border-box;
      
    }

    .bottom {
      width: 100%;
      height: 1rem;
      line-height: 1rem ;

      .left {
        float: left;
        width: 50%;
        border-right:0.01rem solid #caced1;
        box-sizing: border-box;
        color: #888888;
      }

      .right {
        float: left;
        width: 50%;
        color: #308bfe;
      }
    }
  }

}

</style>